<template>
    <div class="card-inner">
        <h6 class="card-title">Company Details</h6>
        <p>Customise your companies settings.</p>
        <div class="row" style="padding-top: 10px">
            <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <span><em class="icon ni ni-building"></em> Company Name </span>
                            <a-input type="text" class="invisibox" :class="{'is-error' : $v.tenantSettings.name.$invalid }" v-model="tenantSettings.name"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span><em class="icon ni ni-mail"></em> Email</span>
                        <a-input type="text" class="invisibox" :class="{'is-error' : $v.tenantSettings.email.$invalid }" v-model="tenantSettings.email"></a-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span><em class="icon ni ni-call"></em> Phone</span>
                        <a-input type="text" class="invisibox" :class="{'is-error' : $v.tenantSettings.phone.$invalid }" v-model="tenantSettings.phone"></a-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span><em class="icon ni ni-link"></em> Website</span>
                        <a-input type="text" class="invisibox" v-model="tenantSettings.websiteUri"></a-input>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <span><em class="icon ni ni-map-pin"></em> Address</span>
                    <a-input type="text" class="invisibox" placeholder="Line 1" :class="{'is-error' : $v.tenantSettings.line1.$invalid }" v-model="tenantSettings.line1"></a-input>
                    <a-input type="text" class="invisibox" placeholder="Line 2" v-model="tenantSettings.line2"></a-input>
                    <a-input type="text" class="invisibox" placeholder="Town" :class="{'is-error' : $v.tenantSettings.town.$invalid }" v-model="tenantSettings.town"></a-input>
                    <a-input type="text" class="invisibox" placeholder="County" :class="{'is-error' : $v.tenantSettings.county.$invalid }" v-model="tenantSettings.county"></a-input>
                    <a-input type="text" class="invisibox" placeholder="Postcode" :class="{'is-error' : $v.tenantSettings.postCode.$invalid }" v-model="tenantSettings.postCode"></a-input>
                    <a-input type="text" class="invisibox" placeholder="Country" v-model="tenantSettings.country"></a-input>
                </div>
                <div class="form-group">
                    <span><em class="icon ni ni-coins"></em> Currency</span>
                    <a-select class="invisibox" style="width: 100%; fontSize:13px" v-model="tenantSettings.defaultCurrencyId">
                        <a-select-option v-for="c in currencies" :key="c.id">
                            {{ c.name }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="col-4">
                <div class="d-flex flex-wrap flex-column align-items-center">
                <div class="  mb-3" style="position:relative;">
                    <div style="position: absolute;right: 5px;top: 5px; cursor:pointer;">
                    <a-popconfirm
                    v-if="$store.state.userSettings.avatarURI"
                        title="Are you sure you want to remove your profile picture?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="confirmAvatarReset"
                        @cancel="cancelAvatarReset"
                    >
                        <em class="icon ni ni-delete"></em>
                    </a-popconfirm>
                    </div>
                    <avatar :src="tenantSettings.avatarURI" :size="100" :rounded="true" v-if="tenantSettings.name" :username="tenantSettings.name" />
                </div>
                <div class="text-center">
                    <div class="text-dark font-weight-bold font-size-18">{{ tenantSettings.name }}</div>
                    <button id="pick-avatar" class="btn btn-secondary btn-sm btn-addon">
                    <span class="btn-addon">
                        <em class="icon ni ni-upload" style="fontSize: 25px"></em>
                    </span>
                    Upload Company Logo
                    </button>
                        <avatar-cropper
                            trigger="#pick-avatar"
                            :labels="{ submit: 'Confirm', cancel: 'Cancel' }"
                            :upload-handler="cropperHandler"
                            />
                </div>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-sm btn-success float-right" @click="updateTenantSettings"><em class="icon ni ni-save mr-1"></em> Save</button>
            </div>
        </div>
    
        <hr class="preview-hr">
        <div class="row">
            <div class="col-2">
                <h6 class="card-title">Fiscal Year</h6>
                <p>Customise the start month of your organisation.</p>
                <div class="row" style="padding-top: 10px">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <span><em class="icon ni ni-calendar"></em> <strong>Fiscal Start Month</strong> </span>
                                    <a-select class="invisibox" style="width: 100%" v-model="tenantSettings.fiscalYear">
                                        <a-select-option v-for="f in fiscalYear" :key="f.name">
                                            {{ f.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <h6 class="card-title">Business Hours</h6>
                <p>Customise the operational hours of your organisation.</p>
                <div class="row" style="padding-top: 10px">
                    <div class="col-3">
                        <table class="table">
                            <thead>
                            <th>
                                Day Of Week
                            </th>
                            <th>
                                Start Time
                            </th>
                            <th>
                                End Time
                            </th>
                            </thead>
                            <tbody>
                                <tr v-for="o in openingTimes" :key="o.id">
                                    <td>
                                        {{ o.dayName }}
                                    </td>
                                    <td>
                                        <a-time-picker format="HH:mm" v-model="o.startTime" @change="updateTenantOpeningTimes(o)"/>
                                    </td>
                                    <td>
                                        <a-time-picker format="HH:mm" v-model="o.endTime" @change="updateTenantOpeningTimes(o)"/>
                                    </td>
                                </tr>
                            </tbody>
                           
                        </table>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12">
            <button class="btn btn-sm btn-success float-right" @click="updateTenantSettings"><em class="icon ni ni-save mr-1"></em> Save</button>
        </div>
        <hr class="preview-hr">
        <div class="row">
            <div class="col-12">
                <button class="btn btn-dark float-right" v-on:click="addCurrency">Add New Currency</button>
                <h6 class="card-title">Currencies</h6>
                <p>Customise the currencies available in SoftSuite.</p>
            </div>
        </div>
        
        <div class="row" style="padding-top: 10px">
            <div class="col-12">
                <vue-good-table v-if="currencies"
                    :columns="currencyColumns"
                    :rows="currencies"
                    styleClass="vgt-table condensed tblhov"
                    @on-cell-click="editCurrency"
                ></vue-good-table>
            </div>
            <div class="col-12">
                <button class="btn btn-sm btn-success float-right" @click="updateTenantSettings"><em class="icon ni ni-save mr-1"></em> Save</button>
            </div>
        </div>

    <!--- Modals --->
        <a-modal v-model="showAddCurrency" title="Add Currency">
            <div class="col-12">
                <label class="form-label">Name</label>
                <a-input placeholder="British Pound" v-model="currency.name"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Symbol</label>
                <a-input placeholder="£" v-model="currency.symbol"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Exchange Rate</label>
                <a-input placeholder="1.00" v-model="currency.rate"></a-input>
            </div>

            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="createCurrency"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>

        <a-modal v-model="showEditCurrency" title="Edit Currency">
            <div class="col-12">
                <label class="form-label">Name</label>
                <a-input placeholder="British Pound"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Symbol</label>
                <a-input placeholder="£"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Exchange Rate</label>
                <a-input placeholder="1.00"></a-input>
            </div>

            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="updateCurrency()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
    </div><!-- .card-inner -->
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper'
import { required } from 'vuelidate/lib/validators'

export default {
    components: { AvatarCropper },
    validations: {
        tenantSettings: {
            name: {
                required
            },
            email: {
                required
            },
            phone: {
                required
            },
            line1: {
                required
            },
            town: {
                required
            },
            county: {
                required
            },
            postCode: {
                required
            }
        }
    },
    data() {
        return {
            currencyColumns: [
            {
                label: 'Name',
                align: 'start',
                sortable: false,
                field: 'name',
            }, {
                label: 'Symbol',
                field: 'symbol'
            }, {
                label: 'Exchange Rate',
                field: 'rate'
            }],
            currencies: [],
            showAddCurrency: false,
            showEditCurrency: false,
            currency: {},
            tenantSettings: {},
            openingTimes: [],
            fiscalYear: [{
                name: 'January'
            },{
                name: 'February'
            },{
                name: 'March'
            },{
                name: 'April'
            },{
                name: 'May'
            },{
                name: 'June'
            },{
                name: 'July'
            },{
                name: 'August'
            },{
                name: 'September'
            },{
                name: 'October'
            },{
                name: 'November'
            },{
                name: 'December'
            },],
        }
    },
    created(){
        this.getTenantSettings()
        this.getCurrencies()
        this.getTenantOpeningTimes()
    },
    methods: {
        addCurrency(){
            this.showAddCurrency = true
        },
        editCurrency(){
            this.showEditCurrency = true
        },
        confirmAvatarReset () {
            this.$http.get('/users/Remove_ProfileImage/')
            .then(() => {
            this.$message.success('Avatar Removed')
            this.refreshUserSettings()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        cancelAvatarReset () {
            this.$message.error('Cancelled')
        },
        cropperHandler (cropper) {
        let avatar = cropper.getCroppedCanvas({ width: 100, height: 100 }).toDataURL(this.cropperOutputMime)
        let imgUpload = { image: avatar }
        this.$http.post('/settings/Upload_CompanyImage/', imgUpload)
            .then((response) => {
            this.tenantSettings.avatar = response.data.fileUri
            this.$message.success('Avatar updated')
            this.getTenantSettings()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        createCurrency(){
            this.$http.post('/settings/Add_Currency', this.currency)
            .then(() => {
                this.getCurrencies()
                this.$message.success('Currency Added')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getCurrencies(){
            this.$http.get('/settings/get_Currencies')
            .then((response) => {
                this.currencies = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getTenantSettings(){
            this.$http.get('/settings/get_TenantSettings')
            .then((response) => {
                this.tenantSettings = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateTenantSettings(){
            if (this.$v.tenantSettings.$invalid) {
                this.$message.error('Please check for any form errors.')
            } else {
                this.$http.post('/settings/Update_TenantSettings', this.tenantSettings)
                .then(() => {
                    this.$message.success('Tenant Updated')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
        getTenantOpeningTimes(){
            this.$http.get('/settings/get_TenantOpeningTimes')
            .then((response) => {
                this.openingTimes = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateTenantOpeningTimes(o){
            this.$http.post('/settings/Update_TenantOpeningTimes', o)
            .then(() => {
                this.getTenantOpeningTimes()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }

}
</script>

<style>

</style>