<template>
    <div class="card-inner">
        <div class="row">
            <div class="col-5" v-if="tenantSettings">
                <div class="row">
                    <div class="col-12">
                        <a-button class="btn btn-dark float-right" @click="addHolidayYear">Add New Holiday Year</a-button>
                        <h6 class="card-title">Holiday Year Selections</h6>
                        <p>Customise the holiday year selections of your organisation.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <vue-good-table v-if="holidayYears"
                            :columns="hyColumns"
                            :rows="holidayYears"
                            styleClass="vgt-table condensed tblhov"
                            @on-cell-click="editHolidayYear"
                            >
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field == 'actions'">
                                    <a-dropdown>
                                        <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
                                        <a-menu slot="overlay">
                                            <a-menu-item style="color: red;" @click="deleteHolidayYear(props.row)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
                <hr class="preview-hr">
                <div class="row">
                    <div class="col-12">
                        <a-button class="btn btn-dark float-right" @click="addWorkPattern">Add New Work Pattern</a-button>
                        <h6 class="card-title">Work Patterns</h6>
                        <p>Customise the work patterns of your organisation.</p>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <vue-good-table v-if="workPatterns"
                            :columns="wpColumns"
                            :rows="workPatterns"
                            styleClass="vgt-table condensed tblhov"
                            @on-cell-click="editWorkPattern"
                        >
                        </vue-good-table>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <h6 class="card-title">National Holidays</h6>
                <p>Customise the national holidays your organisation takes.</p>
                <div class="row" style="padding-top: 10px">
                    <div class="col-10">
                        <table class="table">
                            <thead>
                            <th>
                                Holiday Name
                            </th>
                            <th class="text-right">
                                Enabled / Disabled
                            </th>
                            </thead>
                            <tbody>
                                <tr v-for="nh in nationalHolidays" :key="nh.id">
                                    <td>
                                        {{ nh.name }}
                                    </td>
                                    <td class="text-right">
                                        <a-switch v-model="nh.enabled" @change="updateNationalHolidays(nh)"/>
                                    </td>
                                </tr>
                            </tbody>
                        
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button class="btn btn-sm btn-success float-right" @click="updateTenantSettings"><em class="icon ni ni-save mr-1"></em> Save</button>
        </div>
        <hr class="preview-hr">
        <div class="row">
            <div class="col-12">
                <button class="btn btn-dark float-right" v-on:click="addHoliday">Add New Holiday</button>
                <h6 class="card-title">Business Holidays</h6>
                <p>Customise your business's holidays.</p>
            </div>
        </div>
        <div class="row" style="padding-top: 10px">
            <div class="col-12">
                <vue-good-table v-if="businessHolidays"
                    :columns="columns"
                    :rows="businessHolidays"
                    styleClass="vgt-table condensed tblhov"
                    @on-cell-click="editHoliday"
                >
                    <template slot="table-row" slot-scope="props" v-bind:class="{'xxx': true}">
                        <div v-if="props.column.field == 'holidayDate'">
                            {{ $moment(props.row.holidayDate).format("DD/MM/YYYY") }}
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </div>

        <!-- MODALS -->
        <a-modal v-model="showAddHoliday">
            <div class="col-12">
                <label class="form-label">Holiday Name</label>
                <a-input placeholder="Queen's Birthday" v-model="businessHoliday.name"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Holiday Date</label>
                <a-date-picker format="DD/MM/YYYY" v-model="businessHoliday.holidayDate" style="width: 100%"></a-date-picker>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="createBusinessHoliday"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
        <a-modal v-model="showEditHoliday">
            <div class="col-12">
                <label class="form-label">Holiday Name</label>
                <a-input placeholder="Queen's Birthday" v-model="selectedBusinessHoliday.name"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Holiday Date</label>
                <a-date-picker format="DD/MM/YYYY" v-model="selectedBusinessHoliday.holidayDate" style="width: 100%"></a-date-picker>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="updateBusinessHoliday()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
        <a-modal v-model="showWorkPattern">
            <div class="col-12">
                <label class="form-label">Work Pattern Name</label>
                <a-input placeholder="Mon - Fri" v-model="selectedWorkPattern.name"></a-input>
            </div>
            <div class="col-12">
                <table class="table">
                    <thead>
                        <th>Weekday</th>
                        <th class="text-right">Enabled / Disabled</th>
                    </thead>
                    <tbody v-if="workPatternDays">
                        <tr v-for="wp in workPatternDays" :key="wp.id">
                            <td>{{ wp.name }}</td>
                            <td class="text-right"><a-switch v-model="wp.enabled" /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <label class="form-label">Enabled / Disabled</label>
                <div class="row">
                    <div class="col-12">
                        <a-switch v-model="selectedWorkPattern.enabled"></a-switch>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="saveWorkPattern()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
         <a-modal v-model="showHolidayYear" title="Holiday Year Selection">
            <div class="col-12">
                <label class="form-label">Holiday Year Selection Name</label>
                <a-input placeholder="Mon - Fri" v-model="selectedHolidayYear.name"></a-input>
            </div>
            <div class="col-12">
                <label class="form-label">Start Holiday Date - (Please select the start date you prefer to start on)</label>
                <a-date-picker format="DD/MM/YYYY" v-model="selectedHolidayYear.startDate" style="width: 100%"></a-date-picker>
            </div>
            <div class="col-12">
                <label class="form-label">End Holiday Date - (Please select the end date you prefer to end on)</label>
                <a-date-picker format="DD/MM/YYYY" v-model="selectedHolidayYear.endDate" style="width: 100%"></a-date-picker>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="saveHolidayYear()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
    </div><!-- .card-inner -->
</template>

<script>
export default {
    data() {
        return {
            tenantSettings: null,
            businessHolidays: null,
            workPatterns: null,
            workPatternDays: null,
            workPattern: {},
            holidayYears: null,
            selectedHolidayYear: {},
            selectedWorkPattern: {},
            businessHoliday: {},
            selectedBusinessHoliday: {},
            nationalHolidays: null,
            showAddHoliday: false,
            showEditHoliday: false,
            showWorkPattern: false,
            showHolidayYear: false,
            columns: [
            {
                label: 'Holiday Name',
                align: 'start',
                sortable: false,
                field: 'name',
            }, {
                label: 'Holiday Date',
                field: 'holidayDate'
            }],
            wpColumns: [
            {
                label: 'Work Pattern Name',
                align: 'start',
                sortable: false,
                field: 'name',
            }],
            hyColumns: [
                {
                    label: 'Holiday Year Name',
                    align: 'start',
                    field: 'name'
                },
                {
                    label: 'Start Month',
                    field: 'startMonthName'
                },
                {
                    label: 'Start Day',
                    field: 'startMonthDay'
                },
                {
                    label: 'End Month',
                    field: 'endMonthName'
                },
                {
                    label: 'End Day',
                    field: 'endMonthDay'
                },
                {
                    label: '',
                    field: 'actions'
                }
            ],
            fiscalYear: [{
                name: 'January'
            },{
                name: 'February'
            },{
                name: 'March'
            },{
                name: 'April'
            },{
                name: 'May'
            },{
                name: 'June'
            },{
                name: 'July'
            },{
                name: 'August'
            },{
                name: 'September'
            },{
                name: 'October'
            },{
                name: 'November'
            },{
                name: 'December'
            },],
        }
    },
    created() {
        this.getTenantSettings()
        this.getBusinessHolidays()
        this.getNationalHolidays()
        this.getWorkPatterns()
        this.getHolidayYears()
    },
    methods: {
        // Modals
        addHoliday(){
            this.showAddHoliday = true
        },
        editHoliday(params){
            this.selectedBusinessHoliday = params.row
            this.showEditHoliday = true
        },
        addWorkPattern(){
            this.getWorkPatternDays()
            this.showWorkPattern = true
        },
        editWorkPattern(params){
            this.getWorkPattern(params.row)
            this.selectedWorkPattern = params.row
            this.showWorkPattern = true
        },
        addHolidayYear(){
            this.showHolidayYear = true
        },
        editHolidayYear(params){
            this.selectedHolidayYear = params.row
            this.showHolidayYear = true
        },

        // Get Data
        getTenantSettings(){
            this.$http.get('/settings/get_TenantSettings')
            .then((response) => {
                this.tenantSettings = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getBusinessHolidays() {
            this.$http.get('/settings/Get_Business_Holidays')
            .then((response) => {
                this.businessHolidays = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getNationalHolidays() {
            this.$http.get('/settings/Get_National_Holidays')
            .then((response) => {
                this.nationalHolidays = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getWorkPatterns() {
            this.$http.get('/settings/Get_Work_Patterns')
            .then((response) => {
                this.workPatterns = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getHolidayYears() {
            this.$http.get('/settings/Get_Holiday_Years')
            .then((response) => {
                this.holidayYears = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getWorkPattern(w) {
            this.$http.get('/settings/Get_Work_Pattern/' + w.id)
            .then((response) => {
                this.workPatternDays = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getWorkPatternDays() {
            this.$http.get('/settings/Get_Work_Pattern_Days/')
            .then((response) => {
                this.workPatternDays = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },

        // Save
        saveWorkPattern(){
            this.selectedWorkPattern.workPatternDays = this.workPatternDays

            if (this.selectedWorkPattern.id != null){
                this.updateWorkPattern()
            } else {
                this.createWorkPattern()
            }
        },
        saveHolidayYear(){
            if (this.selectedHolidayYear.id != null){
                this.updateHolidayYear()
            } else {
                this.createHolidayYear()
            }
        },

        // Updates
        updateTenantSettings(){
            this.$http.post('/settings/Update_TenantSettings', this.tenantSettings)
            .then(() => {
                this.$message.success('Tenant Updated')
                this.getTenantSettings()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateNationalHolidays(nh){
            if (nh.enabled === true) {
                this.$http.post('/settings/Add_National_Holiday/', nh)
                .then(() => {
                    this.$message.success('National Holiday Updated')
                    this.getNationalHolidays()
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/settings/Delete_National_Holiday', nh)
                .then(() => {
                    this.$message.success('National Holiday Updated')
                    this.getNationalHolidays()
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
        updateBusinessHoliday(){
            this.$http.post('/settings/Update_Business_Holiday', this.selectedBusinessHoliday)
            .then(() => {
                this.$message.success('Business Holiday Updated')
                this.showEditHoliday = false
                this.getBusinessHolidays()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateWorkPattern(){
            this.$http.post('/settings/Update_Work_Pattern', this.selectedWorkPattern)
            .then(() => {
                this.$message.success('Work Pattern Updated')
                this.showWorkPattern = false
                this.getWorkPatterns()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateHolidayYear(){
            this.$http.post('/settings/Update_Holiday_Year', this.selectedHolidayYear)
            .then(() => {
                this.$message.success('Holiday Year Selection Updated')
                this.showHolidayYear = false
                this.getHolidayYears()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateTenantEnabledWorkPattern(params){
            this.selectedWorkPattern = params
            alert(JSON.stringify(this.selectedWorkPattern))
            this.$http.post('/settings/Update_Enabled_Work_Pattern', this.selectedWorkPattern)
            .then(() => {
                this.$message.success('Work Pattern Updated')
                this.getWorkPatterns()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },

        // Creates
        createBusinessHoliday(){
            this.$http.post('/settings/Add_Business_Holiday', this.businessHoliday)
            .then(() => {
                this.$message.success('Business Holiday Added')
                this.showAddHoliday = false
                this.getBusinessHolidays()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        createWorkPattern(){
            this.$http.post('/settings/Add_Work_Pattern', this.selectedWorkPattern)
            .then(() => {
                this.$message.success('Work Pattern Added')
                this.showWorkPattern = false
                this.getWorkPatterns()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        createHolidayYear(){
            this.$http.post('/settings/Add_Holiday_Year', this.selectedHolidayYear)
            .then(() => {
                this.$message.success('Holiday Year Selection Added')
                this.showWorkPattern = false
                this.getHolidayYears()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },

        // DELETES

        deleteHolidayYear(params){
            this.$http.post('/settings/Delete_Holiday_Year', params)
            .then(() => {
                this.$message.success('Holiday Year Selection Deleted')
                this.getHolidayYears()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }

    }
}
</script>

<style>

</style>