<template>
  <div>
      <div class="col-md-12">
            <div class="nk-block-text">
                <a-button class="btn btn-dark float-right" @click="editTag = true">Add New Tag</a-button>
                <h6>Tags</h6>
                <p>Customise your tags that will be enabled on the desk module.</p>
            </div>
            <div style="padding-top: 15px; margin: 0px">
                <vue-good-table v-if="tags"
                    :columns="tagColumns"
                    :rows="tags"
                    styleClass="vgt-table condensed tblhov"
                    @on-cell-click="showEditTag"
                >
                </vue-good-table>
            </div>
        </div>
        <a-modal v-model="editTag" title="Tag Settings">
            <div class="row">
                <div class="col-12">
                    <span>Tag Name</span>
                    <a-input v-model="selectedTag.name"/>
                </div>
                <div class="col-12">
                    <a-popover v-model="displayAddTag" trigger="click" >
                    <template slot="title" >
                        <!-- <em style="margin-top: 4px; margin-right: -5px; cursor: pointer;" class="icon ni ni-cross-c float-right" @click="closeTag"></em> -->
                        <span>Edit Tag Color</span>
                    </template>
                    <template  slot="content">
                    <div class="row" style="width:290px;">
                        <div class="col-12"><vueswatches inline swatchSize="30" v-model="selectedTag.color" /></div>
                    </div>
                    </template>
                    <span>Tag Color</span>
                    <a-input v-model="selectedTag.color"/>
                </a-popover>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="submit()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
  </div>
</template>

<script>
export default {
    data() {
        return {
            tagColumns: [
                {
                    label: 'Tag',
                    align: 'start',
                    sortable: false,
                    field: 'name',
                }, {
                    label: 'Color', field: 'color'
                }
            ],
            tags: [],
            newTag: { 
                name: '',
                color: ''
            },
            displayAddTag: false,
            closeAddTag: false,
            selectedTag: {},
            editTag: false,
        }
    },
    created() {
        this.getLists()
    },
    methods: {
        getLists() {
            this.$http.get('/lists/Get_Ticket_Tags')
            .then((response) => {
                this.tags = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        closeTag() {
            this.closeAddTag = false
        },
        showEditTag(params) {
            this.editTag = true
            this.selectedTag = params.row
        },

        submit() {
            if(this.selectedTag.id != null) {
                this.updateTag()
            } else {
                this.createTag()
            }
        },       
        updateTag() {
            this.editTag = false
            this.$http.post('/settings/Update_Ticket_Tag', this.selectedTag)
            .then(() => {
                this.getLists()
                this.$message.success('Tag Updated')
                this.selectedTag = null
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        createTag() {
            this.$http.post('/settings/Add_Ticket_Tag', this.selectedTag)
            .then(() => {
                this.getLists()
                this.$message.success('Tag Updated')
                this.selectedTag = null
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>