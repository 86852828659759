<template>
    <div class="col-md-3" v-if="priorities">
        <div class="nk-block-text">
            <div class="row">
                <div class="col-5">
                    <h6>Priorities</h6>
                </div>
                <div class="col-7">
                    <a-button @click="showEditPriority" class="btn btn-sm btn-dark float-right">New Priority</a-button>
                </div>
            </div>
        </div>
        <div style="padding-top: 10px; margin: 0px">
            <vue-good-table v-if="priorities"
                :columns="pColumns"
                :rows="priorities"
                styleClass="vgt-table condensed tblhov"
            >
            <template slot="table-row" slot-scope="props" >
                <div v-if="props.column.field == 'actions'" >
                    <button class="btn btn-sm mr-1" @click="showEditPriority(props)"><em class="icon ni ni-edit"/></button>
                    <a-popconfirm
                        title="Are you sure delete?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="deletePriority(props)"
                        @cancel="cancel"
                    >
                        <button class="btn btn-sm mr-1 text-danger"><em class="icon ni ni-trash"/></button>
                    </a-popconfirm>
                </div>
            </template>
            </vue-good-table>
        </div>
        <a-modal v-model="editPriority" title="Priority Settings">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Priority Name</label>
                    <a-input v-model="selectedPriority.name" style="width: 100%"></a-input>
                </div>
                <div class="col-12">
                    <label class="form-label">SLA Hours</label>
                    <a-input v-model="selectedPriority.slaHours" style="width: 100%"></a-input>
                </div>
                <div class="col-12">
                    <label class="form-label">SLA In Working Hours</label>
                    <a-checkbox v-model="selectedPriority.inWorkingHours" style="width: 100%; margin-left:1px;"></a-checkbox>
                </div>
                <div class="col-12">
                    <a-popover v-model="displayAddTag" trigger="click" >
                        <template slot="title" >
                            <span>Priority Color</span>
                        </template>
                        <template  slot="content">
                        <div class="row" style="width:290px;">
                            <div class="col-12"><vueswatches inline swatchSize="30" v-model="selectedPriority.color" /></div>
                        </div>
                        </template>
                        <span>Priority Color</span>
                        <a-input v-model="selectedPriority.color"/>
                    </a-popover>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="updatePriority()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    created() {
        this.getTenantTicketPriorities()
    },
    data() {
        return {
            priorities: [],
            selectedPriority: {},
            editPriority: false,
            pColumns: [
                {
                    label: 'Priority',
                    align: 'start',
                    sortable: false,
                    field: 'name',
                    width: '60%'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                }
            ],
            displayAddTag: false,
        }
    }, 
    methods: {
        deletePriority(props) {
            this.$http.post('/settings/Delete_TenantPriority', props.row)
            .then(() => {
                this.getTenantTicketPriorities()
                this.$message.success('Priority Deleted')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getTenantTicketPriorities() {
            this.$http.get('/settings/Get_Tenant_TicketPriorities')
            .then((response) => {
                this.priorities = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        showEditPriority(params) {
            if (params.row) {
                this.selectedPriority = params.row
            } else {
                this.selectedPriority = {}
            }
            this.editPriority = true
        },
        updatePriority() {
            this.editPriority = false
            if ( this.selectedPriority.id ) {
                this.$http.post('/settings/Update_Ticket_Priority', this.selectedPriority)
                .then(() => {
                    this.getTenantTicketPriorities()
                    this.$message.success('Priority Updated')
                    this.editPriority = false
                    this.selectedPriority = {}
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/settings/Add_tenantTicket_Priority', this.selectedPriority)
                .then(() => {
                    this.getTenantTicketPriorities()
                    this.$message.success('Priority Created')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
    }
}
</script>

<style>

</style>