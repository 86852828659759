<template>
    <div class="card-inner">
        <a-steps initial="0" :current="current">
            <a-step key="1"  title="Asset Type Details">
                <em slot="icon" class="icon ni ni-user-list-fill"></em>
            </a-step>
            <a-step key="2" title="Asset Type Properties">
                <em slot="icon" class="icon ni ni-building"></em>
            </a-step>
            <a-step key="3" title="Asset Type Attributes">
                <em slot="icon" class="icon ni ni-clipboad-check"></em>
            </a-step>
        </a-steps>
        <div v-if="current == 1">
            <div class="row p-4">
                <div class="col-12">
                    <div class="card card-bordered">
                        <div class="col-12">
                            <h6>Asset Details</h6>
                            <p>Please specify the asset types name and description.</p>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Name Of Asset Type</label>
                            <a-input v-model="assetType.assetTypeName"/>
                        </div>
                        <div class="col-12">
                            <label class="form-label">Asset Type Description</label>
                            <a-textarea v-model="assetType.assetTypeDescription"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="current == 2">
            <div class="row p-4">
                <div class="col-12">
                    <div class="card card-bordered">
                        <div class="col-12">
                            <h6>Asset Properties</h6>
                            <p>These are the fields that have data that does not change. Or at least, not very often. E.G. The color of a car.</p>
                            <table class="table">
                                <thead>
                                    <th style="width:33%;">Label</th>
                                    <th style="width:20%;">Field Type</th>
                                    <th class="text-center" style="width:34%;">Example</th>
                                    <th class="text-center"></th>
                                </thead>
                                <tbody class="text-center">
                                    <tr v-for="assetProperty in assetProperties" :key="assetProperty.id">
                                        <td><a-input v-model="assetProperty.label" placeholder="Label"></a-input></td>
                                        <td><a-select style="width:100%;" v-model="assetProperty.type">
                                            <a-select-option v-for="t in fieldTypes" :key="t.type">
                                                {{ t.name }}
                                            </a-select-option>
                                        </a-select></td>
                                        <td>
                                            <a-input style="width:100%;" v-if="assetProperty.type === 'input'" placeholder="Value"/>
                                            <a-date-picker style="width:100%;" v-if="assetProperty.type === 'datepicker'" format="DD/MM/YYYY" placeholder="Select Date"/>
                                            <a-checkbox v-if="assetProperty.type === 'checkbox'" placeholder="Select Date"/>
                                            <a-input-number style="width:100%;" v-if="assetProperty.type === 'inputNumber'" format="DD/MM/YYYY" placeholder="100"/>
                                            <a-switch v-if="assetProperty.type === 'switch'" placeholder="Select Date"/>
                                            <a-time-picker style="width: 100%;" v-if="assetProperty.type === 'timepicker'" placeholder="Select Time"/>
                                        </td>
                                        <td class="text-center"><a-button @click="clearProperty(assetProperty)" class="btn btn-sm btn-danger">Clear</a-button></td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <div v-for="assetProperty in assetProperties" :key="assetProperty.id">
                                <div class="row">
                                    <div class="col-3">
                                        <a-input v-model="assetProperty.label" placeholder="Label"></a-input>
                                    </div>
                                    <div class="col-3">
                                        <a-select style="width:100%;" v-model="assetProperty.type">
                                            <a-select-option v-for="t in fieldTypes" :key="t.type">
                                                {{ t.name }}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                    <div class="col-6" v-if="assetProperty.type === 'input'">
                                        <a-input placeholder="Value" />
                                    </div>
                                    <div class="col-6" v-if="assetProperty.type === 'datepicker'">
                                        <a-date-picker style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date"/>
                                    </div>
                                    <div class="col-6" v-if="assetProperty.type === 'checkbox'">
                                        <a-checkbox />
                                    </div>
                                    <div class="col-6" v-if="assetProperty.type === 'inputNumber'">
                                        <a-input-number style="width:50%;" format="DD/MM/YYYY" placeholder="100" />
                                    </div>
                                    <div class="col-6" v-if="assetProperty.type === 'switch'">
                                        <a-switch placeholder="Select Date" />
                                    </div>
                                    <div class="col-6" v-if="assetProperty.type === 'timepicker'">
                                        <a-time-picker style="width: 100%;" placeholder="Select Time" />
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-12">
                            <a-button type="dashed" style="width: 100%;" @click="addProperty">
                                <em class="icon ni ni-plus"/> Add Field
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="current == 3">
            <div class="row p-4">
                <div class="col-12">
                    <div class="card card-bordered">
                        <div class="col-12">
                            <h6>Asset Attributes</h6>
                            <p>These are the fields where the data is going to change. E.G. The mileage of a car.</p>
                            <table class="table">
                                <thead>
                                    <th style="width:33%;">Label</th>
                                    <th style="width:20%;">Field Type</th>
                                    <th class="text-center" style="width: 10%;">Enable From Date</th>
                                    <th class="text-center" style="width: 10%;">Enable To Date</th>
                                    <th class="text-center" style="width: 10%;">Enable A Cost</th>
                                    <th class="text-center" style="width:33%;">Example</th>
                                    <th class="text-center"></th>
                                </thead>
                                <tbody class="text-center">
                                    <tr v-for="assetAttribute in assetAttributes" :key="assetAttribute.id">
                                        <td><a-input v-model="assetAttribute.label" placeholder="Label"></a-input></td>
                                        <td><a-select style="width:100%;" v-model="assetAttribute.type">
                                            <a-select-option v-for="t in fieldTypes" :key="t.type">
                                                {{ t.name }}
                                            </a-select-option>
                                        </a-select></td>
                                        <td><a-checkbox v-model="assetAttribute.hasFromDate"></a-checkbox></td>
                                        <td><a-checkbox v-model="assetAttribute.hasToDate"></a-checkbox></td>
                                        <td><a-checkbox v-model="assetAttribute.hasCost"></a-checkbox></td>
                                        <td>
                                            <a-input style="width:100%;" v-if="assetAttribute.type === 'input'" placeholder="Value"/>
                                            <a-date-picker style="width:100%;" v-if="assetAttribute.type === 'datepicker'" format="DD/MM/YYYY" placeholder="Select Date"/>
                                            <a-checkbox v-if="assetAttribute.type === 'checkbox'" placeholder="Select Date"/>
                                            <a-input-number style="width:100%;" v-if="assetAttribute.type === 'inputNumber'" format="DD/MM/YYYY" placeholder="100"/>
                                            <a-switch v-if="assetAttribute.type === 'switch'" placeholder="Select Date"/>
                                            <a-time-picker style="width: 100%;" v-if="assetAttribute.type === 'timepicker'" placeholder="Select Time"/>
                                        </td>
                                        <td class="text-center"><a-button @click="clearAttribute(assetAttribute)" class="btn btn-sm btn-danger">Clear</a-button></td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <div v-for="assetAttribute in assetAttributes" :key="assetAttribute.id">
                                <div class="row">
                                    <div class="col-3">
                                        <a-input v-model="assetAttribute.label" placeholder="Label"></a-input>
                                    </div>
                                    <div class="col-3">
                                        <a-select style="width:100%;" v-model="assetAttribute.type">
                                            <a-select-option v-for="t in fieldTypes" :key="t.type">
                                                {{ t.name }}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                    <div class="col-3" v-if="assetAttribute.type">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <label class="form-label">Enable From Date</label><br>
                                                <a-checkbox v-model="assetAttribute.hasFromDate"></a-checkbox>
                                            </div>
                                            <div class="col-4 text-center">
                                                <label class="form-label">Enable To Date</label><br>
                                                <a-checkbox v-model="assetAttribute.hasToDate"></a-checkbox>
                                            </div>
                                            <div class="col-4 text-center">
                                                <label class="form-label">Enable A Cost</label><br>
                                                <a-checkbox v-model="assetAttribute.hasCost"></a-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3" v-if="assetAttribute.type === 'input'">
                                        <a-input style="width:100%;" placeholder="Value"/>
                                    </div>
                                    <div class="col-3" v-if="assetAttribute.type === 'datepicker'">
                                        <a-date-picker style="width:100%;" format="DD/MM/YYYY" placeholder="Select Date"/>
                                    </div>
                                    <div class="col-6" v-if="assetAttribute.type === 'checkbox'">
                                        <a-checkbox placeholder="Select Date"/>
                                    </div>
                                    <div class="col-6" v-if="assetAttribute.type === 'inputNumber'">
                                        <a-input-number style="width:50%;" format="DD/MM/YYYY" placeholder="100"/>
                                    </div>
                                    <div class="col-6" v-if="assetAttribute.type === 'switch'">
                                        <a-switch placeholder="Select Date"/>
                                    </div>
                                    <div class="col-6" v-if="assetAttribute.type === 'timepicker'">
                                        <a-time-picker style="width: 100%;" placeholder="Select Time"/>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-12">
                                    <a-button type="dashed" style="width: 100%;" @click="addAttribute">
                                        <em class="icon ni ni-plus"/> Add Field
                                    </a-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a-button class="btn-sm btn btn-dark" v-if="current != 1" @click="current -= 1">
                    <em class="icon ni ni-undo"/> Previous
                </a-button>
                <a-button class="btn-sm btn btn-danger" style="margin-left: 42%;" v-if="current == 2" @click="assetProperties = []">
                    <em class="icon ni ni-undo"/> Reset
                </a-button>
                <a-button class="btn-sm btn btn-danger" style="margin-left: 42%;" v-if="current == 3" @click="assetAttributes = []">
                    <em class="icon ni ni-undo"/> Reset
                </a-button>
                <a-button class="btn-sm btn btn-dark float-right" v-if="current != 3" @click="current += 1">
                    <em class="icon ni ni-redo"/> Next
                </a-button>
                <a-button class="btn-sm btn btn-success float-right" v-if="current == 3" @click="saveAssetType()">
                    <em class="icon ni ni-save"/> Save
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        assetTypeId: {
            type: Number
        },
        hasChanged: {
            type: Boolean
        }
    },
    data() {
        return {
            assetType: {},
            current: 1,
            assetProperties: [],
            assetAttributes: [],
            fieldTypes: [
                { name: 'Input', type: 'input'},
                { name: 'Date Picker', type: 'datepicker'},
                { name: 'Time Picker', type: 'timepicker'},
                { name: 'Checkbox', type: 'checkbox'},
                { name: 'Number', type: 'inputNumber'},
                { name: 'Switch', type: 'switch'},
            ],
        }
    },
    watch: {
        hasChanged: function() {
            if (this.assetTypeId != null) {
                this.current = 1
                this.getAssetType()
                this.getAssetProperties()
                this.getAssetAttributes()
            } else {
                this.current = 1
                this.assetType = {}
                this.assetProperties = []
                this.assetAttributes = []
            }
        }
    },
    created() {
        if (this.assetTypeId != null) {
            this.current = 1
            this.getAssetType()
            this.getAssetProperties()
            this.getAssetAttributes()
        } else {
            this.current = 1
            this.assetType = {}
            this.assetProperties = []
            this.assetAttributes = []
        }
    },
    methods: {
        addProperty() {
            let assetProperty = JSON.parse( JSON.stringify(this.assetType) )
            assetProperty.id = this.$uuid.v4()
            this.assetProperties.push(assetProperty)
        },
        addAttribute() {
            let assetAttribute = JSON.parse( JSON.stringify(this.assetType) )
            assetAttribute.id = this.$uuid.v4()
            this.assetAttributes.push(assetAttribute)
        },
        clearProperty(a){
            var i = 0
            for (var obj in this.assetProperties) {
               
                if (this.assetProperties[i].id === a.id) {
                    this.assetProperties.splice(i, 1)
                    console.log(obj)
                }
                i++
            }
        },
        clearAttribute(a){
            var i = 0
            for (var obj in this.assetAttributes) {
               
                if (this.assetAttributes[i].id === a.id) {
                    this.assetAttributes.splice(i, 1)
                    console.log(obj)
                }
                i++
            }
        },
        saveAssetType(){
            this.assetType.properties = JSON.parse(JSON.stringify(this.assetProperties))
            this.assetType.attributes = JSON.parse(JSON.stringify(this.assetAttributes))

            if (this.assetTypeId != null) {
                this.$http.post('/assets/Update_AssetType', this.assetType)
                .then(() => {
                    this.$message.success('Asset Type Saved')
                    this.$emit('assetTypeSaved')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/assets/Add_AssetType', this.assetType)
                .then(() => {
                    this.$message.success('Asset Type Saved')
                    this.$emit('assetTypeSaved')
                    this.current = 1
                    this.assetType = {}
                    this.assetProperties = []
                    this.assetAttributes = []
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
        getAssetType(){
            this.$http.get('/assets/Get_AssetType/' + this.assetTypeId)
            .then((response) => {
                this.assetType = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getAssetProperties(){
            this.$http.get('/assets/Get_AssetProperties/' + this.assetTypeId)
            .then((response) => {
                this.assetProperties = response.data.properties
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getAssetAttributes(){
            this.$http.get('/assets/Get_AssetAttributes/' + this.assetTypeId)
            .then((response) => {
                this.assetAttributes = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>