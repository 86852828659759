<template>
  <div class="col-md-3" v-if="issueTypes">
        <div class="nk-block-text">
            <div class="row">
                <div class="col-5">
                <h6 style="padding-top:8px">Issue Types</h6>
                </div>
                <div class="col-7">
                    <a-button @click="showEditIssueType" class="btn btn-sm btn-dark float-right">New Type</a-button>
                </div>
            </div>
        </div>
        <div style="padding-top: 10px; margin: 0px">
            <vue-good-table
                :columns="issueTypeColumns"
                :rows="issueTypes"
                styleClass="vgt-table condensed tblhov"
            >
            <template slot="table-row" slot-scope="props" >
                <div v-if="props.column.field === 'actions'" >
                    <button class="btn btn-sm mr-1" @click="showEditIssueType(props)"><em class="icon ni ni-edit"/></button>
                    <a-popconfirm
                        title="Are you sure delete?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="deleteIssueType(props)"
                        @cancel="cancel"
                    >
                        <button class="btn btn-sm mr-1 text-danger"><em class="icon ni ni-trash"/></button>
                    </a-popconfirm>
                </div>
                <div v-else-if="props.column.field === 'isDefault'">
                    <span v-if="props.row.isDefault === true">True</span>
                    <span v-if="props.row.isDefault === false">False</span>
                </div>
            </template>
            </vue-good-table>
        </div>
        <a-modal v-model="editIssueType" title="Issue Type Settings">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Issue Type Name</label>
                    <a-input v-model="selectedIssueType.name"/>
                </div>
                <div class="col-12">
                    <label class="form-label">Issue Type Allowance Unit</label>
                    <a-select style="width:100%" v-model="selectedIssueType.ticketIssueTypeAllowanceUnitId">
                        <a-select-option v-for="unit in ticketIssueTypeAllowanceUnits" :key="unit.id">
                            {{ unit.text }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-12">
                    <label class="form-label">Issue Type Allowance Time Span</label>
                    <a-select style="width:100%" v-model="selectedIssueType.ticketIssueTypeAllowanceTimeSpanId">
                        <a-select-option v-for="timeSpan in ticketIssueTypeAllowanceTimeSpans" :key="timeSpan.id">
                            {{ timeSpan.text }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-12">
                    <label class="form-label">Default Issue Type</label><br>
                    <a-switch v-model="selectedIssueType.isDefault"></a-switch>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="updateIssueType()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            issueTypeColumns: [
                {
                    label: 'Issue Type',
                    align: 'start',
                    sortable: false,
                    field: 'name',
                    width: '60%'
                },
                {
                    label: 'Is Default',
                    field: 'isDefault'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                }
            ],
            issueTypes: [],
            ticketIssueTypeAllowanceUnits: [],
            ticketIssueTypeAllowanceTimeSpans: [],
            editIssueType: false,
            selectedIssueType: {},
        }
    },
    created() {
        this.getLists()
        this.getTenantIssueTypes()
    },
    methods: {
        deleteIssueType(props) {
            if (props.row.hasActiveAllocations) {
                this.$message.error('This issue type has active allocations. Please remove them to delete')
            } else {
                this.$http.post('/settings/Delete_TenantIssueType', props.row)
                .then(() => {
                    this.getTenantIssueTypes()
                    this.$message.success('Issue Type Deleted')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
        getLists() {
            this.$http.get('/lists/Get_List/ticketIssueTypeAllowanceTimeSpans')
            .then((response) => {
                this.ticketIssueTypeAllowanceTimeSpans = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })

            this.$http.get('/lists/Get_List/ticketIssueTypeAllowanceUnits')
            .then((response) => {
                this.ticketIssueTypeAllowanceUnits = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getTenantIssueTypes(){
            this.$http.get('/settings/Get_Tenant_TicketIssueTypes')
            .then((response) => {
                this.issueTypes = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        showEditIssueType(params) {
            if ( params.row ) {
                this.selectedIssueType = params.row
            } else {
                this.selectedIssueType = {}
            }
            this.editIssueType = true
        },
        
        updateIssueType() {
            this.editIssueType = false
            if (this.selectedIssueType.id) {
                this.$http.post('/settings/Update_Ticket_Issue_Type', this.selectedIssueType)
                .then(() => {
                    this.getTenantIssueTypes()
                    this.$message.success('Issue Type Updated')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/settings/Add_Ticket_Issue_Type', this.selectedIssueType)
                .then(() => {
                    this.getTenantIssueTypes()
                    this.$message.success('Issue Type Added')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
    }
}
</script>

<style>

</style>