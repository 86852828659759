<template>
    <div>
        <div class="row" style="padding: 10px;">
            <div class="col-12">
                <h5>Zoho Integration <span class="badge badge-success">Enabled</span></h5>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <a-button class="btn btn-info float-right" @click="syncAccounts"><em class="icon ni ni-reload"/> Sync</a-button>
                <h6>Sync Zoho CRM Accounts</h6>
                <p>Begin an initial sync on the Accounts you have saved in Zoho CRM. </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a-button class="btn btn-info float-right" @click="syncContacts"><em class="icon ni ni-reload"/> Sync</a-button>
                <h6>Sync Zoho CRM Contacts</h6>
                <p>Begin an initial sync on the Contacts you have saved in Zoho CRM. </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a-button class="btn btn-info float-right" @click="syncProducts"><em class="icon ni ni-reload"/> Sync</a-button>
                <h6>Sync Zoho CRM Products</h6>
                <p>Begin an initial sync on the Products you have saved in Zoho CRM. </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a-button class="btn btn-info float-right" @click="syncTasks"><em class="icon ni ni-reload"/> Sync</a-button>
                <h6>Sync Zoho CRM Tasks</h6>
                <p>Begin an initial sync on the Tasks you have saved in Zoho CRM. </p>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
        syncAccounts() {
            this.$http.get('/ZohoConnect/import_accounts/')
            .then(() => { 
                this.$message.success('Accounts synced!!') 
            })
            .catch(() => {
                this.$message.error('There has been an error')
            }) 
        },
        syncContacts() {
            alert('Contacts Sync Begin')
        },
        syncProducts() {
            alert('Products Sync Begin')
        },
        syncTasks() {
            alert('Tasks Sync Begin')
        }
    }
}
</script>

<style>

</style>