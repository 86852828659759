<template>
  <div class="nk-block">
        <div class="nk-block-head">
            <div class="nk-block-head-content">
                <h3 class="nk-block-title page-title">SoftSuite Settings</h3>
                <div class="nk-block-des">
                </div>
            </div>
        </div><!-- .nk-block-head -->
        <div class="card card-bordered">
            <div class="card-inner-group">
                <a-tabs>
                    <a-tab-pane key="1" tab="General">
                        <general/>
                    </a-tab-pane>
                    <!-- <a-tab-pane key="2" tab="Auth">
                        <auth/>
                    </a-tab-pane> -->
                    <a-tab-pane key="3" tab="Ticket Settings" v-if="$hasTenantModule(1)">
                        <tickets/>
                    </a-tab-pane>
                    <a-tab-pane key="4" tab="Business Holiday Settings" v-if="$hasTenantModule(3)">
                        <businessHolidays/>
                    </a-tab-pane>
                    <a-tab-pane key="5" tab="Tags Settings" v-if="$hasTenantModule(2)">
                        <tags/>
                    </a-tab-pane>
                    <a-tab-pane key="6" tab="Asset Manager Settings">
                        <assetManagerSettings/>
                    </a-tab-pane>
                    <a-tab-pane key="7" tab="Integration Settings">
                        <integrationSettings/>
                    </a-tab-pane>
                </a-tabs>
            </div><!-- .card-inner-group -->
        </div><!-- .card -->
    </div>
</template>

<script>
import general from '@/components/crm/settings/general.vue'
// import auth from '@/components/crm/settings/auth.vue'
import tickets from '@/components/crm/settings/tickets.vue'
import businessHolidays from '@/components/crm/settings/businessHolidays.vue'
import tags from '@/components/crm/settings/tags.vue'
import assetManagerSettings from '@/components/crm/settings/assetManagerSettings.vue'
import integrationSettings from '@/components/crm/settings/integrationSettings.vue'

export default {
    components: { general, tickets, businessHolidays, tags, assetManagerSettings, integrationSettings },
    created() {
        this.getView()
    },
    methods: {
        getView() {
            this.$http.get('/ViewAccess/crmsettings')
            .then(() => {
            })
            .catch(() => {
            })
        },
    }
}
</script>

<style>

</style>