<template>
<div>
    <div class="card-inner" v-if="ticketSettings">
        <div class="row">
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Enable Priorities</h6>
                <p>This setting will enable Ticket Priorities. You will be able to manage and set priorities on tickets.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <div class="custom-control custom-switch mr-n2">
                                <input type="checkbox" class="custom-control-input" v-model="ticketSettings.prioritiesEnabled" id="priorities">
                                <label class="custom-control-label" for="priorities"></label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Enable User Assignment</h6>
                <p>This setting will enable Ticket User Assignment. You will be able to manage and assign tickets to a certain user on the system.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <div class="custom-control custom-switch mr-n2">
                                <input type="checkbox" class="custom-control-input" v-model="ticketSettings.userAssignmentEnabled" id="userAssignment">
                                <label class="custom-control-label" for="userAssignment"></label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Enable Automatic Start Time Allocation</h6>
                <p>This setting will enable Ticket Time Allocation to be automated.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <div class="custom-control custom-switch mr-n2">
                                <input type="checkbox" class="custom-control-input" v-model="ticketSettings.autoAllocateTimeStart" id="start">
                                <label class="custom-control-label" for="start"></label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3" v-if="ticketSettings.autoAllocateTimeStart">
                <div class="nk-block-text">
                <h6>Start Time Allocation On Status</h6>
                <p>This setting will enable Ticket Time Allocation to be started on a status you specify.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <a-select style="width:200px;" v-model="ticketSettings.autoAllocateTimeStatusId">
                                <a-select-option v-for="s in statuses" :key="s.id">
                                    {{ s.name }}
                                </a-select-option>
                            </a-select>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Enable Automatic Stop Time Allocation</h6>
                <p>This setting will enable Ticket Time Allocation to be automated.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <div class="custom-control custom-switch mr-n2">
                                <input type="checkbox" class="custom-control-input" v-model="ticketSettings.autoAllocateTimeStop" id="stop">
                                <label class="custom-control-label" for="stop"></label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Enable Tags</h6>
                <p>This setting will enable Ticket Tags. You will be able to manage and set tags on tickets.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <div class="custom-control custom-switch mr-n2">
                                <input type="checkbox" class="custom-control-input" v-model="ticketSettings.tagsEnabled" id="tags">
                                <label class="custom-control-label" for="tags"></label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Enable Attachments</h6>
                <p>This setting will enable Ticket Attachments. You will be able to upload attachments to a specific ticket.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                            <div class="custom-control custom-switch mr-n2">
                                <input type="checkbox" class="custom-control-input" v-model="ticketSettings.attachmentsEnabled" id="attachments">
                                <label class="custom-control-label" for="attachments"></label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                <h6>Ticket Issue Max Hours</h6>
                <p>Each ticket will be split based on the formula ( total hours <strong>Hours / Value</strong>). E.g specifying <strong>{{ticketSettings.ticketIssueMaxHours}}</strong> will mean a ticket that takes 7 hours will deduct <strong>{{ticketSettings.ticketIssueMaxHours ? Math.ceil(7 / ticketSettings.ticketIssueMaxHours) : 1}}</strong> tickets from an accounts allowance.</p>
                </div>
                <div class="nk-block-actions">
                    <ul class="align-center gx-3">
                        <li class="order-md-last">
                           <input style="width:100px;" type="number" class="form-control" min="1" v-model="ticketSettings.ticketIssueMaxHours" id="attachments">
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button v-on:click="updateSettings" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
            </div>
        </div>
        <hr class="preview-hr">

        <!-- tables -->
        <div class="row">
            <tenantTicketPriorities/>
            <tenantTicketStatuses/>
            <tenantTicketTags/>
            <tenantTicketIssueTypes/>
        </div>
        
    </div><!-- .card-inner -->
</div>
</template>

<script>
import tenantTicketPriorities from '@/components/desk/tickets/settings/priorities.vue'
import tenantTicketStatuses from '@/components/desk/tickets/settings/statuses.vue'
import tenantTicketTags from '@/components/desk/tickets/settings/tags.vue'
import tenantTicketIssueTypes from '@/components/desk/tickets/settings/issueTypes.vue'
export default {
    components: {
        tenantTicketPriorities,
        tenantTicketStatuses,
        tenantTicketTags,
        tenantTicketIssueTypes,
    },
    data() {
        return {
            search: '',
            ticketSettings: null,
            statuses: []
        }
    },
    created() {
        this.getSettings()
    },
    methods: {
        getSettings () {
            this.$http.get('/settings/Get_Ticket_Settings')
            .then((response) => {
                this.ticketSettings = response.data
                this.getTenantTicketStatuses()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateSettings() {
            this.$http.post('/settings/Update_Ticket_Settings', this.ticketSettings)
            .then(() => {
                this.$message.success('Settings Updated')
                this.getSettings()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getTenantTicketStatuses() {
            this.$http.get('/settings/Get_Tenant_TicketStatuses')
            .then((response) => {
                this.statuses = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>