<template>
    <div class="card-inner">
        <div class="row">
            <div class="col-12">
                <a-button class="btn btn-dark float-right" @click="addType()">New Type</a-button>
                <h6 class="title">Asset Types</h6>
                <p>Here you can manage all of your asset types.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <vue-good-table
                    v-if="assetTypes"
                    :columns="columns"
                    :rows="assetTypes"
                    styleClass="vgt-table condensed tblhov"
                >
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'createdDate'">
                            {{ $moment(props.row.createdDate).format('H:mm A LL')}}
                        </div>
                        <div v-else-if="props.column.field === 'updatedDate'">
                            {{ $moment(props.row.updatedDate).format('H:mm A LL')}}
                        </div>
                        <div v-else-if="props.column.field === 'actions'">
                            <a-dropdown>
                                <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
                                <a-menu slot="overlay">
                                    <a-menu-item style="color: black;" @click="editType(props)"><em class="icon ni ni-edit"/> Edit Type</a-menu-item>
                                    <a-menu-item style="color: red;" @click="deleteAssetType(props)"><em class="icon ni ni-cross-circle"/> Delete Type</a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </div>
        <a-modal title="Asset Type" :height="'80%'" :width="'80%'" :footer="null" :closable="true" v-model="showType">
            <addAssetTypeWizard @assetTypeSaved="getAssetTypes()" :assetTypeId="assetTypeId" :hasChanged="hasChanged"></addAssetTypeWizard>
        </a-modal>
    </div>
</template>

<script>
import addAssetTypeWizard from '@/components/assets/addAssetTypeWizard.vue'

export default {
    components: { addAssetTypeWizard },
    data() {
        return {
            selectedAssetType: {},
            assetTypes: [],
            assetTypeId: null,
            showType: false,
            columns: [
                { label: 'Asset Name', align: 'start', field: 'assetTypeName' },
                { label: 'Created By', field: 'createdByUser'},
                { label: 'Created Date', field: 'createdDate'},
                { label: 'Updated By', field: 'updatedByUser'},
                { label: 'Updated Date', field: 'updatedDate'},
                { label: 'Actions', sortable: false, thClass: 'text-center', tdClass: 'text-center', field: 'actions'},
            ],
            hasChanged: false,
        }
    },
    created() {
        this.getAssetTypes()
    },
    methods: {
        changed(){
            this.assetTypeId = null
            if (this.hasChanged === false) {
                this.hasChanged = true
            } else {
                this.hasChanged = false
            }
        },
        addType(){
            this.changed()
            this.assetTypeId = null
            this.showType = true
        },
        editType(props){
            this.changed()
            this.assetTypeId = props.row.id
            this.showType = true
        },
        closeTemplate(){
            this.assetType = {}
            this.showType = false
        },
        getAssetTypes(){
            this.$http.get('/assets/Get_AssetTypes')
            .then((response) => {
                this.showType = false
                this.assetTypes = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        deleteAssetType(props) {
            this.$http.post('/assets/Delete_AssetType', props.row)
            .then(() => {
                this.$message.success('Asset Type Deleted')
                this.getAssetTypes()
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>