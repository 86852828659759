<template>
    <div class="card-inner">
        <div class="row" style="padding-bottom: 20px;">
            <div class="col-12">
                <a-button class="btn btn-dark float-right" @click="showInitialSettings">Start Integration</a-button>
                <h6>Integration Settings</h6>
                <p>This screen will display your integration settings with various applications. Please press 'Start Integration' to get started. </p>
            </div>
        </div>

        <!-- ZOHO -->
        <div v-if="integration.chosenIntegration === 'zoho'">
            <zoho/>
        </div>
        <!-- END ZOHO -->

        <!-- MODAL -->
        <a-modal v-model="initialSettings" title="Integration">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">
                        Please Choose The Platform You Would Like To Integrate?
                    </label>
                    <a-select class="invisibox" style="width: 100%;" v-model="integration.chosenIntegration">
                        <a-select-option :key="'zoho'">Zoho</a-select-option>
                    </a-select>
                </div>
            </div>
            <div v-if="integration.chosenIntegration === 'zoho'">
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Zoho Email Address</label>
                        <a-input class="invisibox" v-model="integration.user_mail"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Zoho Client Id</label>
                        <a-input class="invisibox" v-model="integration.clientId"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Zoho Client Secret</label>
                        <a-input class="invisibox" v-model="integration.clientSecret"/>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Zoho Access Token</label>
                        <a-input class="invisibox" v-model="integration.access_token"/>
                        <small>You can generate this using [Generate Code] within https://api-console.zoho.com/ under [Self Client]</small>
                    </div>
                </div> 
            </div>
            
            <template slot="footer">
                <a-button class="btn btn-success" @click="saveIntegrationSettings">
                    <em class="icon ni ni-save"/> Save
                </a-button>
            </template>
        </a-modal>
        <!-- END MODAL -->
    </div><!-- .card-inner -->
</template>

<script>
import zoho from '@/components/crm/settings/integrations/zoho.vue'

export default {
    components: { zoho },
    data() {
        return {
            initialSettings: false,
            chosenIntegration: '',
            integration: {access_token: null, clientSecret: null, clientId: null, user_mail: null  }
        }
    },
    methods: {
        showInitialSettings() {
            this.initialSettings = true
        },
        saveIntegrationSettings() {
            this.$http.post('/ZohoConnect/connect_Zoho', this.integration)
            .then(() => { 
                this.$message.success('Zoho Connected') 
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
    }
}
</script>

<style>

</style>