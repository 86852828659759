<template>
    <div class="col-md-3" v-if="tags">
            <div class="nk-block-text">
                <h6>Tags</h6>
            </div>
            <div style="padding-top: 10px; margin: 0px">
                <vue-good-table v-if="tags"
                    :columns="tagColumns"
                    :rows="tags"
                    styleClass="vgt-table condensed tblhov"
                    @on-cell-click="showEditTag"
                >
                </vue-good-table>
            </div>
        <a-modal v-model="editTag" title="Tag Settings">
            <div class="row">
                <div class="col-12">
                    <span>Tag Name</span>
                    <a-input v-model="selectedTag.name"/>
                </div>
                <div class="col-12">
                    <a-popover v-model="displayAddTag" trigger="click" >
                    <template slot="title" >
                        <!-- <em style="margin-top: 4px; margin-right: -5px; cursor: pointer;" class="icon ni ni-cross-c float-right" @click="closeTag"></em> -->
                        <span>Edit Tag Color</span>
                    </template>
                    <template  slot="content">
                    <div class="row" style="width:290px;">
                        <div class="col-12"><vueswatches inline swatchSize="30" v-model="selectedTag.color" /></div>
                    </div>
                    </template>
                    <span>Tag Color</span>
                    <a-input v-model="selectedTag.color"/>
                </a-popover>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="updateTag()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tagColumns: [
                {
                    label: 'Tag',
                    align: 'start',
                    sortable: false,
                    field: 'name',
                }, {
                    label: 'Color', field: 'color'
                }
            ],
            tags: [],
            editTag: false,
            displayAddTag: false,
            closeAddTag: false,
            selectedTag: {},
        }
    },
    created() {
        this.getTenantTags() 
    },
    methods: {
        getTenantTags() {
            this.$http.get('/lists/Get_Ticket_Tags')
            .then((response) => {
                this.tags = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        showEditTag(params) {
            this.editTag = true
            this.selectedTag = params.row
        },
        closeTag() {
            this.closeAddTag = false
        },
        updateTag() {
            this.editTag = false
            if ( this.selectedTag.id ) {
                this.$http.post('/settings/Update_Ticket_Tag', this.selectedTag)
                .then(() => {
                    this.getTenantTags()
                    this.$message.success('Tag Updated')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/settings/Add_Ticket_Tag', this.selectedTag)
                .then(() => {
                    this.getTenantTags()
                    this.$message.success('Tag Added')
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
            
        },
    }
}
</script>

<style>

</style>