<template>
  <div class="col-md-3" v-if="statuses">
        <div class="nk-block-text">
            <div class="row">
                <div class="col-5">
                    <h6>Statuses</h6>
                </div>
                <div class="col-7">
                    <a-button @click="showEditStatus" class="btn btn-sm btn-dark float-right">New Status</a-button>
                </div>
            </div>
        </div>
        <div style="padding-top: 10px; margin: 0px">
            <vue-good-table v-if="statuses"
                :columns="sColumns"
                :rows="statuses"
                styleClass="vgt-table condensed tblhov"
            >
            <template slot="table-row" slot-scope="props" >
                <div v-if="props.column.field == 'actions'" >
                    <button class="btn btn-sm mr-1" @click="showEditStatus(props)"><em class="icon ni ni-edit"/></button>
                    <a-popconfirm
                        title="Are you sure delete?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="deleteStatus(props)"
                        @cancel="cancel"
                    >
                        <button class="btn btn-sm mr-1 text-danger"><em class="icon ni ni-trash"/></button>
                    </a-popconfirm>
                </div>
            </template>
            </vue-good-table>
        </div>
        <a-modal v-model="editStatus" title="Status Settings">
            <div class="row">
                <div class="col-12">
                    <label class="form-label">Status Name</label>
                    <a-input v-model="selectedStatus.name" style="width: 100%"></a-input>
                </div>
                <div class="col-12">
                    <label class="form-label">Is Initial Status</label><br>
                    <a-switch v-model="selectedStatus.initialStatus" ></a-switch>
                </div>
                <div class="col-12">
                    <label class="form-label">Is Final Status</label><br>
                    <a-switch v-model="selectedStatus.finalStatus" ></a-switch>
                </div>
                <div class="col-12">
                    <a-popover v-model="displayAddTag" trigger="click" >
                        <template slot="title" >
                            <!-- <em style="margin-top: 4px; margin-right: -5px; cursor: pointer;" class="icon ni ni-cross-c float-right" @click="closeTag"></em> -->
                            <span>Status Color</span>
                        </template>
                        <template  slot="content">
                        <div class="row" style="width:290px;">
                            <div class="col-12"><vueswatches inline swatchSize="30" v-model="selectedStatus.color" /></div>
                        </div>
                        </template>
                        <span class="form-label">Status Color</span>
                        <a-input v-model="selectedStatus.color"/>
                    </a-popover>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn btn-sm btn-success" @click="updateStatus()"><em class="icon ni ni-save mr-1"></em> Save</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            statuses: [],
            selectedStatus: {},
            editStatus: false,
            sColumns: [
                {
                    label: 'Status',
                    align: 'start',
                    sortable: false,
                    field: 'name',
                    width: '60%'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    thClass: 'text-center',
                    tdClass: 'text-center'
                }
            ],
            displayAddTag: false,
        }
    },
    created() {
        this.getTenantTicketStatuses()
    },
    methods: {
        deleteStatus(props) {
            this.$http.post('/settings/Delete_TenantStatus', props.row)
            .then(() => {
                this.getTenantTicketStatuses()
                this.$message.success('Status Deleted')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getTenantTicketStatuses() {
            this.$http.get('/settings/Get_Tenant_TicketStatuses')
            .then((response) => {
                this.statuses = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateStatus() {
            this.editStatus = false
            if ( this.selectedStatus.id ) {
                this.$http.post('/settings/Update_Tenant_TicketStatus', this.selectedStatus)
                .then(() => {
                    this.$message.success('Status Updated')
                    this.getTenantTicketStatuses()
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/settings/Add_Tenant_TicketStatus', this.selectedStatus)
                .then(() => {
                    this.$message.success('Status Added')
                    this.getTenantTicketStatuses()
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            }
        },
        showEditStatus(params) {
            this.editStatus = true
            if ( !params.row ) {
                this.selectedStatus = {}
            } else {
                this.selectedStatus = params.row
            }
        },
    }
}
</script>

<style>

</style>